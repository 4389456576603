import store from '@/store';
import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../../routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import {
  getPermissionKeyFromPermission,
  getRouteKeyFromRouteName,
  getRouteNameFromRouteKey,
} from '@/helpers/authorization/authorization';
import { RootPermissionsEnum } from '@/router/permissions/root-permissions.enum';
import { PagesNamesEnum } from '@/router/pages.names.enum';
import { isPartnerAuthenticatedLocally } from "@/api/useAuthStatus";

const { useGetters } = createNamespacedHelpers(store, 'authentication');

export const useAuthorization = async (to: Route, from: Route, next: any) => {
  const { isAccountOwner } = await useGetters(['isAccountOwner']);

  const grantedPermissionsList: RootPermissionsEnum[] = [...(store.getters['teamMember/grantedPermissions'] || [])];

  const routeKey = getRouteKeyFromRouteName(to.name as RoutingRouteEnum);
  if (
    isAccountOwner.value
    || !to.matched.some((route) => route.meta.requireAuth)
    // If a route requires auth & the partner is not yet authenticated, we don't need to check permissions yet.
    || (to.matched.some((route) => route.meta.requireAuth) && !isPartnerAuthenticatedLocally())
  ) {
    next();
    return;
  }

  if (to.name === RoutingRouteEnum.NotAuthorized) {
    next({ params: { pageName: getRouteNameFromRouteKey(routeKey as PagesNamesEnum) } });
    return;
  }
  processPermissions(grantedPermissionsList, routeKey, next);
};

const processPermissions = (grantedPermissionsList: RootPermissionsEnum[], routeKey: string, next: any) => {
  const hasPermission = grantedPermissionsList.some((permission) => {
    return getPermissionKeyFromPermission(permission) == routeKey;
  });

  if (!hasPermission) {
    next({
      name: RoutingRouteEnum.NotAuthorized,
      params: {
        pageName: getRouteNameFromRouteKey(routeKey as PagesNamesEnum),
      },
    });
    return;
  } else {
    next();
  }
};
